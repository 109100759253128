<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.evaluation_situation.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-textarea
        id="input-description"
        name="input-description"
        v-model="$v.evaluation_situation.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <template
      v-if="
        institution &&
        institution.kl_assessment &&
        ![
          'duoc_ejecucion_practica_test',
          'duoc_entrega_de_encargo_test',
          'duoc_presentacion_test',
        ].includes(evaluation_situation.internal_use_id)
      "
    >
      <b-form-group
        label-for="enable-question"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="3"
        class="m-0 p-0"
      >
        <template #label>
          <div class="block-title-container">
            <div>Permitir crear Preguntas</div>
            <div>
              <InfoTooltip
                :tooltip_text="`Esta opción le permite crear los diferentes tipos de preguntas dentro de la ${$getVisibleNames(
                  'evaluations2.test',
                  false,
                  'Instrumento'
                )}.`"
              ></InfoTooltip>
            </div>
          </div>
        </template>
        <div>
          <b-form-checkbox
            class="mt-2"
            size="md"
            v-model="evaluation_situation.enable_questions"
            @change="changeStateCheckbox"
          >
          </b-form-checkbox>
        </div>
      </b-form-group>
      <b-form-group
        v-if="evaluation_situation.enable_questions"
        label-for="enable_questions"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="3"
        class="m-0 p-0"
      >
        <template #label>
          <div class="block-title-container">
            <div>Opciones de Preguntas</div>
            <div>
              <InfoTooltip
                :tooltip_text="`Opciones de preguntas que pueden estar dentro de la ${$getVisibleNames(
                  'evaluations2.test',
                  false,
                  'Instrumento'
                )}.`"
              ></InfoTooltip>
            </div>
          </div>
        </template>
        <div class="container">
          <div class="row">
            <b-form-checkbox
              class="mt-1 mr-2"
              size="md"
              v-model="evaluation_situation.enable_redaction_questions"
            ></b-form-checkbox>
            <span>Redacción</span>
          </div>
          <div class="row">
            <b-form-checkbox
              class="mt-1 mr-2"
              size="md"
              v-model="evaluation_situation.enable_matching_questions"
            ></b-form-checkbox>
            <span>Emparejamiento</span>
          </div>
          <div class="row">
            <b-form-checkbox
              class="mt-1 mr-2"
              size="md"
              v-model="evaluation_situation.enable_simple_selection_questions"
            ></b-form-checkbox>
            <span>Selección Simple</span>
          </div>
          <div class="row">
            <b-form-checkbox
              class="mt-1 mr-2"
              size="md"
              v-model="evaluation_situation.enable_multiple_selection_questions"
            ></b-form-checkbox>
            <span>Selección Múltiple</span>
          </div>
        </div>
      </b-form-group>
    </template>
    <b-form-group
      v-if="!isNaN(this.evaluation_situation.id)"
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2"
    >
      <ModificationDate :Information="evaluation_situation"></ModificationDate>
    </b-form-group>
    <div class="row">
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
export default {
  name: "EvaluationSituationForm",
  components: {
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  mixins: [validationMixin],
  props: {
    EvaluationSituation: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          enable_questions: true,
          enable_redaction_questions: true,
          enable_matching_questions: true,
          enable_simple_selection_questions: true,
          enable_multiple_selection_questions: true,
          internal_use_id: null,
          updated_by: null,
          update_date: null,
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      evaluation_situation: { ...this.EvaluationSituation },
    };
  },
  validations: {
    evaluation_situation: {
      name: {
        required,
        minLength: minLength(2),
      },
      description: {
        minLength: minLength(0),
      },
    },
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
    }),
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.evaluation_situation[name];
      return $dirty ? !$error : null;
    },
    changeStateCheckbox() {
      if (!this.evaluation_situation.enable_questions) {
        this.evaluation_situation.enable_redaction_questions = false;
        this.evaluation_situation.enable_matching_questions = false;
        this.evaluation_situation.enable_simple_selection_questions = false;
        this.evaluation_situation.enable_multiple_selection_questions = false;
      }
    },
    save() {
      this.$v.evaluation_situation.$touch();
      if (this.$v.evaluation_situation.$anyError) {
        return;
      }
      if (isNaN(this.evaluation_situation.id)) {
        this.createEvaluationSituation();
      } else this.updateEvaluationSituation();
    },
    createEvaluationSituation() {
      this.$restful
        .Post(`/teaching/evaluation-situation/`, this.evaluation_situation)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "teaching.evaluationsituation",
                false,
                "Situación Evaluativa"
              ) + " creada."
            )
          );
          this.$emit("created", response);
        });
    },
    updateEvaluationSituation() {
      this.$restful
        .Patch(
          `/teaching/evaluation-situation/${this.evaluation_situation.id}/`,
          this.evaluation_situation
        )
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "teaching.evaluationsituation",
                false,
                "Situación Evaluativa"
              ) + " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
  },
  created() {
    if (
      [
        "duoc_ejecucion_practica_test",
        "duoc_entrega_de_encargo_test",
        "duoc_presentacion_test",
      ].includes(this.evaluation_situation.internal_use_id)
    ) {
      this.evaluation_situation.enable_questions = false;
      this.evaluation_situation.enable_redaction_questions = false;
      this.evaluation_situation.enable_matching_questions = false;
      this.evaluation_situation.enable_simple_selection_questions = false;
      this.evaluation_situation.enable_multiple_selection_questions = false;
    }
  },
};
</script>
<style scoped>
.block-title-container {
  display: flex;
  justify-content: space-between;
}
.container {
  display: flex;
  flex-wrap: wrap;
}

.row {
  flex: 1 0 100%;
  display: flex;
  align-items: center;
}
</style>